import { IShop } from "../interfaces/shop.interface";
import {
  IAdditionalFeatures,
  ISubscriptionInitial,
  PackageData,
} from "../interfaces/subscription.interface";
import { checkPackageLimits } from "../pages/subscriptions/util/packageUtil";

export const subscriptionPackageRestriction = (
  userSubscriptions: ISubscriptionInitial,
  subscriptionPackages: PackageData[],
  featureCount: {
    inventoriesCount: number;
    debtCount: number;
  },
  dispatch: any,
  check: IAdditionalFeatures["check"]
) => {
  const checkRestriction = checkPackageLimits(
    userSubscriptions.packageNumber,
    subscriptionPackages,
    featureCount,
    dispatch,
    check
  );

  return checkRestriction;
};

export const checkWhiteListedShops = (
  currentShopId: string,
  shopList: IShop[],
  subscriptionPackages: PackageData[],
  userSubscriptions: ISubscriptionInitial,
  featureCount: {
    inventoriesCount: number;
    debtCount: number;
  },
  dispatch: any,
  check: IAdditionalFeatures["check"] = "others"
) => {
  const subscriptionShops = shopList.filter((shop) => shop.userId === userSubscriptions.userId);
  const allowedShopCount = subscriptionPackages[userSubscriptions.packageNumber].shopCount;
  const allowedShops =
    allowedShopCount !== -1 ? subscriptionShops?.slice(0, allowedShopCount) : subscriptionShops;

  const isCurrentShopAllowed =
    allowedShops.findIndex((shop: IShop) => shop.shopId === currentShopId) !== -1;

  if (!isCurrentShopAllowed) {
    const isProgress = subscriptionPackageRestriction(
      userSubscriptions,
      subscriptionPackages,
      featureCount,
      dispatch,
      check
    );
    return isProgress;
  }

  return true;
};
