import styled from "styled-components";
import { fontSizes } from "../../GlobalStyles/theme";

export const Container = styled.div`
  display: flex;
  height: 3rem;
  padding: 0rem 2rem;
  align-items: center;
  justify-content: center;
  background-color: #dd4949;
  cursor: pointer;
  position: relative;

  .close{
    position: absolute;
    right: 1rem;
    width: 3rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 768px) {
      padding: 0 1rem;
  }
`;
