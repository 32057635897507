/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { FunctionComponent, ReactElement, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { toggleSnackbarClose } from "../../app/slices/snacbar";
import { FiX } from "react-icons/fi";
import { fontSizes } from "../../GlobalStyles/theme";

const Snackbar: FunctionComponent<{ timeout: any }> = ({
  timeout,
}): ReactElement<any, any> | null => {
  const dispatch = useAppDispatch();

  const {
    toggleSnackbar: SHOW,
    snackbarMessage: MESSAGE,
    color,
  } = useAppSelector((state) => state.snackbar);

  // convert the timeout prop to pass into the styled component
  const TIME = (timeout - 500) / 1000 + "s";

  let TIMER: any;
  const handleTimeout = () => {
    TIMER = setTimeout(() => {
      dispatch(toggleSnackbarClose());
    }, timeout);
  };

  const handleClose = () => {
    clearTimeout(TIMER);
    dispatch(toggleSnackbarClose());
  };

  useEffect(() => {
    if (SHOW) {
      handleTimeout();
    }
    return () => {
      clearTimeout(TIMER);
    };
  }, [SHOW, TIMER]);

  return (
    <>
      {SHOW && (
        <Container color={color} time={TIME}>
          <div className="message">
            <span>{color === "DEFAULT" ? "info" : color?.toLocaleLowerCase()}</span>
            <p>{MESSAGE}</p>
          </div>
          <Button color={color} onClick={handleClose}>
            <FiX />
          </Button>
        </Container>
      )}
    </>
  );
};

const fadein = keyframes`
    from {
      top: 0;
      opacity: 0;
    }
    to {
      top: 4.5rem;
      opacity: 1;
    }
`;

const fadeout = keyframes`
    from {
      top: 4.5rem;
      opacity: 1;
    }
    to {
      top: 0;
      opacity: 0;
    }
`;

export type SnackbarType = "SUCCESS" | "DANGER" | "DEFAULT" | "INFO" | "WARNING";

interface ContainerProps {
  color?: SnackbarType;
  time?: any;
}

const getSnackBarColor = (color: ContainerProps["color"] = "DEFAULT") => {
  switch (color) {
    case "DANGER":
      return { bg: "#FEEEEE", color: "#F76262", border: "#FCC9C9" };
    case "INFO":
      return { bg: "#FFF6EA", color: "#F1B46C", border: "#F0D3B2" };
    case "WARNING":
      return { bg: "#FFF6EA", color: "#F1B46C", border: "#F0D3B2" };
    case "SUCCESS":
      return { bg: "#E9F5EE", color: "#219653", border: "#DEEFE5" };
    case "DEFAULT":
    default:
      return { bg: "#FFF6EA", color: "#F1B46C", border: "#F0D3B2" };
  }
};

const Container = styled.div<ContainerProps>`
  position: absolute;
  z-index: 9999999999;
  top: 4.5rem;
  left: 50%;
  height: auto;
  min-height: 4rem;
  transform: translateX(-50%);
  padding: 0.625rem 1rem;
  border-radius: 0.7rem;
  border: 1px solid ${(props) => getSnackBarColor(props.color).border};
  color: ${(props) => getSnackBarColor(props.color).color};
  background-color: ${(props) => getSnackBarColor(props.color).bg};
  font-size: ${fontSizes.base};
  display: flex;
  width: 40%;
  justify-content: space-between;
  align-items: center;
  animation: ${fadein} 0.5s, ${fadeout} 0.5s ${(props: { time?: any }) => props.time};

  .message {
    display: flex;
    flex-direction: column;

    gap: 0.5rem;
    span {
      text-transform: capitalize;
      color: ${(props) => getSnackBarColor(props.color).color};
      font-weight: 700;
      font-size: ${fontSizes.base};
    }

    p {
      width: 100%;
      font-size: ${fontSizes.small};
    }
  }

  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;

const Button = styled.button<ContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.875rem;
  padding: 0;
  margin-left: 1rem;
  height: 1.75rem;
  width: 1.75rem;
  min-width: 1.75rem;
  text-align: center;
  border: none;
  border-radius: 0.3rem;
  color: ${(props) => getSnackBarColor(props.color).color};
  background-color: ${(props) => getSnackBarColor(props.color).border};
  cursor: pointer;
  font-size: 1.5rem;

  &:hover {
    opacity: 0.5;
  }
`;

export default Snackbar;
