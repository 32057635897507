import { gql } from "@apollo/client";

const ImageSchema = `
  localURL
  smallImageOnlineURL
  mediumImageOnlineURL
  largeImageOnlineURL
`;

export const shopSchema = `
  shopId
  shopName
  shopAddress
  shopPhone
  isPublished
  city
  state
  userId
  shopApprovalStatus
  rejectionReason
  updatedAt
  createdAt
  shopCategoryId
  shopCategoryName
  checkoutMethod
  latitude
  longitude
  distance
  isDisabled
  discountEnabled
  maximumDiscount
  deviceLocked
  isExpiryDateEnabled
  currencyCode
  isSurplusEnabled
  city
  state
  cityId
  stateId
  countryId
  countryName
  Images {
    ${ImageSchema}
  }
`;

export const countrySchema = `
  name
  countryCode
  shortName
  countryId
`;

export const GET_ALL_SHOPS = gql`
  query GetShops{
    getUsersShops{
      ${shopSchema}
    }
  }
`;

export const FETCH_ALL_SHOPS = gql`
  query FetchUserShops {
    fetchUserShops {
      ${shopSchema}
    }
  }
`;
export const CREATE_SHOP = gql`
  mutation CreateShop(
    $shopName: String!
    $shopAddress: String!
    $currencyCode: String
    $shopCategoryId: ID
    $shopCategoryName: String
    $shopPhone: String
    $city:String
    $state:String
    $cityId: Int
    $stateId: Int
    $countryId: String!
    $countryName:String!
  ) {
    createShop(
      shopName: $shopName
      shopAddress: $shopAddress
      currencyCode: $currencyCode
      shopCategoryId: $shopCategoryId
      shopCategoryName: $shopCategoryName
      shopPhone: $shopPhone
      city: $city
      state: $state
      cityId: $cityId
      stateId: $stateId
      countryId: $countryId
      countryName: $countryName
    ) {
      ${shopSchema}
    }
  }  
`;
export const GET_SHOP = gql`
  query GetShop(
    $shopId: ID,
    $shopTag: String,
    $latitude: Float,
    $longitude: Float,
       
  ){
    getShop(
      shopId: $shopId,
      shopTag: $shopTag,
      latitude: $latitude,
      longitude: $longitude,
    ) {
      countries {
        ${countrySchema}
      }
      result {
        ${shopSchema}
      }
    }
  }
`;
export const UPDATE_SHOP = gql`
  mutation updateShop(
    $shopId: ID!
    $shopName: String
    $shopPhone: String
    $shopAddress: String
    $isPublished: Boolean
    $city: String
    $state: String
    $shopCategoryId: ID
    $shopCategoryName: String
    $checkoutMethod: CheckoutMethods
    $maximumDiscount: Float
    $currencyCode: String
    $discountEnabled: Boolean
    $isExpiryDateEnabled: Boolean
    $isSurplusEnabled: Boolean
  ) {
    updateShop(
      shopId: $shopId
      shopName: $shopName
      shopPhone: $shopPhone
      shopAddress: $shopAddress
      isPublished: $isPublished
      city: $city
      state: $state
      shopCategoryId: $shopCategoryId
      shopCategoryName: $shopCategoryName
      checkoutMethod: $checkoutMethod
      maximumDiscount: $maximumDiscount
      discountEnabled: $discountEnabled
      currencyCode: $currencyCode
      isExpiryDateEnabled: $isExpiryDateEnabled
      isSurplusEnabled: $isSurplusEnabled
    )
  }
`;

export const UPDATE_SURPLUS = gql`
  mutation updateSurplus($shopId: ID!, $surplusEnabled: String!) {
    updateSurplus(shopId: $shopId, surplusEnabled: $surplusEnabled)
  }
`;

export const GET_SURPLUS_STATUS = gql`
  query GetSurplusStatus( $shopId: ID!) {
      getSurplusStatus(shopId: $shopId)
  }
`;

export const GET_SHOP_CATEGORIES = gql`
  query GetShopCategories {
    getShopCategories {
      shopCategoryId
      shopCategoryName
      description
    }
  }
`;

export const GET_SHOP_INVENTORY_CATEGORIES = gql`
  query GetAllShopInventoryCategory($shopId: ID!) {
    getAllShopInventoryCategory(shopId: $shopId) {
      inventoryCategoryId
      parentId
      inventorycategoryName
      shopId
      updatedAt
      createdAt
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation DeleteInventoryCategory($inventoryCategoryId: ID) {
    deleteInventoryCategory(inventoryCategoryId: $inventoryCategoryId)
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation UpdateInventoryCategory(
    $inventoryCategoryId: ID
    $inventorycategoryName: String
    $shopId: ID
  ) {
    updateInventoryCategory(
      inventoryCategoryId: $inventoryCategoryId
      inventorycategoryName: $inventorycategoryName
      shopId: $shopId
    )
  }
`;

export const GENERATE_DEVICE_ID = gql`
  mutation GenerateDeviceId {
    generateDeviceId {
      deviceUUID
    }
  }
`;

export const DELETE_SHOP = gql`
  mutation DeleteShop($shopId: ID!) {
    deleteShop(shopId: $shopId)
  }
`;

export const UPDATE_SHOP_SETTINGS = gql`
  mutation UpdateShopSetting($shopId: ID!, $key: String!, $value:String!) {
    updateShopSetting(shopId:$shopId, key:$key, value:$value) {
      shopId
      key
      value
    }
  }
`;

export const GET_SHOP_SETTINGS = gql`
  query GetShopSetting($shopId: ID!, $key: String!) {
    getShopSetting(shopId: $shopId, key: $key) {
      shopId
      key
      value
    }
  }
`;

export const GET_ALL_SHOP_SETTINGS = gql`
  query GetAllShopSetting($shopId: ID!) {
    getAllShopSetting(shopId: $shopId) {
      key
      value
    }
  }
`;
