import { IconContext } from "react-icons";
import { GoDotFill } from "react-icons/go";
import { GrInProgress } from "react-icons/gr";
import { IoCloudDone, IoCloudOfflineSharp } from "react-icons/io5";
import { VscSync, VscSyncIgnored } from "react-icons/vsc";
import { Flex, Text } from "../../GlobalStyles/CustomizableGlobal.style";
import { Colors, fontSizes } from "../../GlobalStyles/theme";
import { convertToLocalDateTime } from "../../helper/date";
import { clientServerSocket } from "../../services/clientServerSocket";
import { syncStatusProps } from "../dashboard-wrapper/dashboard-wrapper";
import { SyncButton } from "../sidebar/style";
import Cancel from "./../../assets/cancel.svg";

interface SyncModalProps {
  setShowSyncModal: React.Dispatch<React.SetStateAction<boolean>>;
  data: syncStatusProps;
  lastSyncedTime: number;
  startSync: () => void;
}

const SyncModal = ({ setShowSyncModal, data, lastSyncedTime, startSync }: SyncModalProps) => {
  const isConnected = clientServerSocket.connected;

  return (
    <Flex bg="#fff" borderRadius="0.75rem" padding="1rem 2rem" direction="column" width="100%">
      <Flex margin="1rem 0 0" gap="1rem" justifyContent="space-between">
        <Text color={Colors.primaryColor} fontSize={fontSizes.base}>
          Synchronization
        </Text>
        <img
          src={Cancel}
          alt="close modal"
          onClick={() => setShowSyncModal(false)}
          style={{ cursor: "pointer" }}
        />
      </Flex>
      <Flex
        margin="1rem 0 0"
        gap="0.3rem"
        alignItems="center"
        justifyContent="center"
        bg={Colors.backgroundColor}
        padding="8px"
        borderRadius="0.5rem"
        width="100%"
        height="auto"
        direction="column"
      >
        <Flex alignItems="center" justifyContent="center" gap="0.3rem">
          <IconContext.Provider
            value={{ color: isConnected ? "green" : Colors.primaryColor, size: "1em" }}
          >
            <>{<GoDotFill />}</>
          </IconContext.Provider>
          {isConnected ? (
            <Text color="green">Online</Text>
          ) : (
            <Text color={Colors.primaryColor}>Offline</Text>
          )}
        </Flex>

        <Flex alignItems="center" justifyContent="center" gap="0.3rem">
          <SyncButton onClick={() => startSync()}>
            <IconContext.Provider value={{ color: Colors.secondaryColor, size: "1em" }}>
              <>{!data.running ? <VscSync /> : <VscSyncIgnored />}</>
            </IconContext.Provider>
          </SyncButton>

          <Flex alignItems="center" justifyContent="center" width="auto">
            <div style={{ color: Colors.secondaryColor, fontSize: fontSizes.small }}>
              {!data.running && data.totalRecordsToPush ? (
                <>
                  <span style={{ fontWeight: 700 }}>
                    {data.totalRecordsToPush === 0 ? "" : data.totalRecordsToPush}
                  </span>{" "}
                  record(s) yet to be uploaded to server
                </>
              ) : (
                ""
              )}
            </div>

            <Flex cursor="pointer" alignItems="center" justifyContent="center" width="auto">
              {data.running ? (
                <Flex
                  margin="0 0 0 0.5rem"
                  fontSize={fontSizes.small}
                  justifyContent="center"
                  alignItems="center"
                  gap="0.5rem"
                >
                  <Flex
                    fontSize={fontSizes.small}
                    width="auto"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Text color={Colors.secondaryColor}>
                      {`${Math.round(data.percentage ?? 0)}%`}
                    </Text>
                  </Flex>

                  <Flex width="auto" justifyContent="center" alignItems="center">
                    <Text color={Colors.secondaryColor}>
                      {data.totalRecordsToPush
                        ? `${data.totalRecordsToPush} Uploading/Syncing Records...`
                        : " Getting Updates..."}
                    </Text>
                  </Flex>
                </Flex>
              ) : (
                <Flex
                  flexWrap="wrap"
                  justifyContent="flex-start"
                  margin="0 0 0 0.5rem"
                  onClick={() => setShowSyncModal(true)}
                >
                  <Text color={Colors.secondaryColor}>
                    Last Updated:{" "}
                    {lastSyncedTime
                      ? convertToLocalDateTime(new Date(lastSyncedTime), "D MMM, YY. h:mmA")
                      : "--"}
                  </Text>
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>

        {data.error && (
          <Flex alignItems="center" justifyContent="center" gap="0.3rem">
            <Text color={Colors.red}>Error: {data.error}</Text>
          </Flex>
        )}
      </Flex>

      {!isConnected ? (
        <Flex margin="1rem 0 0" alignItems="center" justifyContent="center" gap="0.3rem">
          <IconContext.Provider value={{ color: Colors.primaryColor, size: "1.5em" }}>
            <>{<IoCloudOfflineSharp />}</>
          </IconContext.Provider>
          <Text color={Colors.primaryColor} fontSize={fontSizes.base}>
            No internet connection
          </Text>
        </Flex>
      ) : !data.error && data.running ? (
        <Flex margin="1rem 0 0" alignItems="center" justifyContent="center" gap="0.3rem">
          <IconContext.Provider value={{ color: Colors.secondaryColor, size: "1em" }}>
            <>{<GrInProgress />}</>
          </IconContext.Provider>
          <Text color={Colors.secondaryColor}>Synchronization in progress</Text>
        </Flex>
      ) : (
        <Flex margin="1rem 0 0" alignItems="center" justifyContent="center" gap="0.5rem">
          <Flex alignItems="center" justifyContent="center" width="auto">
            <IconContext.Provider value={{ color: Colors.secondaryColor, size: "1.5em" }}>
              <>{<IoCloudDone />}</>
            </IconContext.Provider>
          </Flex>
          <Flex
            direction="column"
            alignItems="start"
            justifyContent="center"
            gap="0.1rem"
            width="auto"
          >
            <Text color={Colors.secondaryColor} fontSize={fontSizes.base}>
              Synchronization successful
            </Text>
            <Text color={Colors.secondaryColor} fontSize={fontSizes.small}>
              All your records are synced to the cloud
            </Text>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default SyncModal;
