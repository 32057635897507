import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface SyncData {
  running: boolean;
  count: number;
  total: number;
  percentage: number;
  error: string | null;
  totalRecordsToPush: number;
  lastSyncDate: number | string;
  progress: number;
}

interface syncStatusType {
  status: boolean;
  bannerData: SyncData | null;
  showSyncModal: boolean;
}

const initialState: syncStatusType = {
  status: false,
  bannerData: null,
  showSyncModal: false,
};

const syncStatus = createSlice({
  name: "status",
  initialState,
  reducers: {
    getSyncStatus: (state, { payload }) => {
      state.status = payload;
    },
    setBannerData: (state, { payload }) => {
      state.bannerData = payload;
    },
    setShowSyncModal: (state, { payload }) => {
      state.showSyncModal = payload;
    },
  },
});

export const { getSyncStatus, setBannerData, setShowSyncModal } = syncStatus.actions;
export const currentSyncStatus = (state: RootState) => state.syncStatus;
export const bannerStatus = (state: RootState) => state.syncStatus;
export default syncStatus.reducer;
