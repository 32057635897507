/* eslint-disable indent */
import { IImage } from "../interfaces/image.interface";
import { IInventory } from "../interfaces/inventory.interface";
import { IReceipt } from "../interfaces/receipt.interface";
import { ISales } from "../interfaces/sales.interface";
import { PushNotificationPayload } from "../interfaces/shop.interface";
import { IVariationList } from "../pages/inventory/add/add";
import { formatNumber } from "../utils/formatValues";
import { isPositive } from "../utils/helper.utils";
import { formatAmountIntl } from "./format";
import { rpcClient } from "./rpcClient";
import axios from "axios";

interface IUpload {
  files: any;
  key?: "userId" | "inventoryId" | "shopId";
  id?: string;
  shopId?: string;
}

export type InventoryCostPrice = number | { pieces: number; pack: number };

export type InventoryType = "PIECES" | "PACK" | "PIECES_AND_PACK" | "VARIATION" | "NON_TRACKABLE";

export const getInventoryType = (inventory: IInventory): InventoryType => {
  const { TrackableItem, isVariation } = inventory;

  if (isVariation) return "VARIATION";

  if (!inventory.trackable) return "NON_TRACKABLE";

  if (TrackableItem) {
    if (TrackableItem.perPack && !TrackableItem.unitPrice && !TrackableItem.unitPiecesCostPrice) {
      return "PACK";
    }

    if (TrackableItem.perPack) return "PIECES_AND_PACK";
  }

  return "PIECES";
};

export const getInventoryPrice = (
  inventory: IInventory,
  variationIndex?: number,
  isPackAndPieces: boolean | undefined = undefined
): any => {
  const inventoryType = inventory?.inventoryType;

  let price: number = 0;
  const { trackable, isVariation, Variations, TrackableItem, NonTrackableItem } = inventory;

  if (inventoryType === "PIECES_AND_PACK") {
    const pieces: number = TrackableItem?.unitPrice!;
    const pack: number = TrackableItem?.packPrice!;
    return { pieces, pack };
  }

  if (trackable) {
    if (isVariation) {
      price = Variations![variationIndex!]?.price ?? 0;
    } else {
      price =
        inventoryType !== "PIECES" ? TrackableItem?.packPrice || 0 : TrackableItem?.unitPrice || 0;
    }
  } else {
    price = NonTrackableItem?.sellingPrice ?? 0;
  }

  return price;
};

export const getInventoryCostPrice = (
  inventory: IInventory,
  variationId?: string,
  isPackAndPieces: boolean | undefined = undefined
): any => {
  const inventoryType = inventory?.inventoryType;

  if (inventoryType === "VARIATION" && variationId) {
    const cost = Number(
      inventory?.Variations?.filter((variation) => variation.variationId === variationId)?.[0]
        .cost || 0
    );
    return cost;
  }

  if (inventoryType === "PIECES" || inventoryType === "PACK") {
    const trackableItem = inventory.TrackableItem;
    return inventoryType === "PIECES"
      ? trackableItem?.unitPiecesCostPrice!
      : trackableItem?.unitPackCostPrice!;
  }

  if (inventoryType === "NON_TRACKABLE") {
    return inventory.NonTrackableItem?.costPrice!;
  }

  if (inventoryType === "PIECES_AND_PACK") {
    const trackableItem = inventory.TrackableItem;
    const pieces: number = trackableItem?.unitPiecesCostPrice!;
    const pack: number = trackableItem?.unitPackCostPrice!;
    return { pieces, pack };
  }

  return null;
};

export const getVariationQuantity = (inventory: IInventory, variationId: string) => {
  const isSharedQuantity = inventory.isSharedQuantity;

  if (isSharedQuantity) {
    return inventory.quantity;
  }

  const inventoryQty = inventory?.InventoryQuantity?.filter(
    (qty) => qty?.variationId === variationId
  ).reduce((prevValue, inventoryQuantity) => prevValue + inventoryQuantity.quantity!, 0);

  return inventoryQty ?? 0;
};

export const getTotalVariationQuantity = (inventory: IInventory, inventoryId: string) => {
  const inventoryQty = inventory?.InventoryQuantity?.filter(
    (qty) => qty?.inventoryId === inventoryId && qty?.variationId !== null
  ).reduce((prevValue, inventoryQuantity) => prevValue + inventoryQuantity.quantity!, 0);

  return inventory.isSharedQuantity ? inventory.quantity || 0 : inventoryQty ?? 0;
};

export const getInventoryQuantity = (inventory: IInventory) => {
  const { quantity = 0, quantityInPacks = 0, quantityInPieces = 0, inventoryType } = inventory;

  if ((quantity || quantityInPacks || quantityInPieces) <= 0) {
    return "Out of Stock";
  }

  switch (inventoryType) {
    case "PIECES_AND_PACK":
      return `${formatNumber(quantityInPieces)} pieces | ${formatNumber(quantityInPacks)} pack`;
    case "PACK":
      return `${formatNumber(quantityInPacks)} packs`;
    case "PIECES":
      return `${formatNumber(quantityInPieces)} items`;
    case "VARIATION":
      return `${getTotalVariationQuantity(inventory, inventory.inventoryId as string)} items`;
    case "NON_TRACKABLE":
    default:
      return `${formatNumber(quantity)} items`;
  }
};

export const getQuantity = (inventory: IInventory): any => {
  const result = inventory?.isVariation
    ? getTotalVariationQuantity(inventory, inventory?.inventoryId!)
    : getInventoryType(inventory) === "PIECES_AND_PACK"
    ? {
        pieces: formatNumber(
          inventory?.quantityInPacks! * inventory?.TrackableItem?.perPack! +
            inventory?.quantityInPieces!
        ),
        pack: formatNumber(inventory?.quantityInPacks),
      }
    : inventory?.inventoryType !== "PIECES"
    ? formatNumber(inventory?.quantityInPacks)
    : formatNumber(inventory?.quantityInPieces);

  return result;
};

export const getVariantQuantity = (variationId: string, inventory: IInventory) => {
  if (inventory.isSharedQuantity) {
    return inventory.quantity ?? 0;
  }

  const inventoryQty = inventory?.InventoryQuantity?.filter(
    (qty) => qty?.variationId === variationId
  ).reduce((prevValue, inventoryQuantity) => prevValue + inventoryQuantity.quantity!, 0);
  return inventoryQty ?? 0;
};

export const getProductCostPrice = (inventory: IInventory, type?: "pieces" | "pack") => {
  const costPrice = getInventoryCostPrice(inventory, undefined, true);
  let pieces = 0;
  let pack = 0;

  if (typeof costPrice === "number") {
    // costPrice is a number
  } else if (costPrice !== null) {
    // costPrice is an object with "pieces" and "pack" properties
    pieces = costPrice?.pieces ?? 0;
    pack = costPrice?.pack ?? 0;
  }
  return inventory?.isVariation
    ? "--"
    : getInventoryType(inventory) === "PIECES_AND_PACK"
    ? `${formatAmountIntl(undefined, Number(pieces))}(${formatAmountIntl(undefined, Number(pack))})`
    : formatAmountIntl(undefined, Number(getInventoryCostPrice(inventory, undefined)));
};

export const getVariationMinMaxPrice = (inventory: IInventory) => {
  if (!inventory?.Variations?.length) {
    return { minPrice: 0, maxPrice: 0 };
  }

  const { minPrice, maxPrice } = inventory.Variations.reduce(
    (acc, variant) => ({
      minPrice: Math.min(acc.minPrice, variant.price ?? Infinity),
      maxPrice: Math.max(acc.maxPrice, variant.price ?? -Infinity),
    }),
    { minPrice: Infinity, maxPrice: -Infinity }
  );

  return `${formatAmountIntl(undefined, minPrice)} - ${formatAmountIntl(undefined, maxPrice)}`;
};

export const getProductCostPriceUnformatted = (inventory: IInventory, type?: "pieces" | "pack") => {
  const costPrice = getInventoryCostPrice(inventory, undefined, true);
  let pieces = 0;
  let pack = 0;

  if (typeof costPrice === "number") {
    // costPrice is a number
  } else if (costPrice !== null) {
    // costPrice is an object with "pieces" and "pack" properties
    pieces = costPrice?.pieces ?? 0;
    pack = costPrice?.pack ?? 0;
  }
  return inventory?.isVariation
    ? "--"
    : getInventoryType(inventory) === "PIECES_AND_PACK"
    ? `${Number(pieces)}(${Number(pack)})`
    : Number(getInventoryCostPrice(inventory, undefined));
};

export const getProductCostPriceAsNumber = (val: IInventory, variationId?: string) => {
  const formattedCostPrice = getProductCostPrice(val);
  let parsedCurrentCostPrice = 0;

  if (val.inventoryType === "VARIATION") {
    const variation = val.Variations?.find((variant) =>
      variant.variationId === variationId);

    parsedCurrentCostPrice = variation?.cost || 0;
  }

  if (formattedCostPrice) {
    const numericPart = formattedCostPrice.match(/[\d,.]+/)?.[0];
    if (numericPart) {
      parsedCurrentCostPrice = parseFloat(numericPart.replace(/,/g, ""));
    }
  }

  return parsedCurrentCostPrice;
};

export const getSellingPricePP = (inventory: IInventory) => {
  const costPrice = getInventoryPrice(inventory);
  let pieces = 0;
  let pack = 0;

  if (typeof costPrice === "number") {
    // costPrice is a number
  } else if (costPrice !== null) {
    // costPrice is an object with "pieces" and "pack" properties
    pieces = costPrice.pieces;
    pack = costPrice.pack;
  }
  return getInventoryType(inventory) === "PIECES_AND_PACK" ? { pieces: pieces, pack: pack } : null;
};

export const getProductSellingPrice = (inventory: IInventory, type?: "pieces" | "pack") => {
  const costPrice = getInventoryPrice(inventory);
  let pieces = 0;
  let pack = 0;

  if (typeof costPrice === "number") {
    // costPrice is a number
  } else if (costPrice !== null) {
    // costPrice is an object with "pieces" and "pack" properties
    pieces = costPrice.pieces ?? 0;
    pack = costPrice.pack ?? 0;
  }
  return inventory?.isVariation
    ? getVariationMinMaxPrice(inventory)
    : getInventoryType(inventory) === "PIECES_AND_PACK"
    ? `${formatAmountIntl(undefined, Number(pieces))}(${formatAmountIntl(undefined, Number(pack))})`
    : formatAmountIntl(undefined, Number(getInventoryPrice(inventory)));
};

export const getProductSellingPriceAsNumber = (val: IInventory) => {
  if (val.inventoryType === "VARIATION") return 0;

  const formattedSellingPrice = getProductSellingPrice(val);
  let parsedCurrentSellingPrice = 0;

  if (formattedSellingPrice) {
    const numericPart = (formattedSellingPrice as string).match(/[\d,.]+/)?.[0];
    if (numericPart) {
      parsedCurrentSellingPrice = parseFloat(numericPart.replace(/,/g, ""));
    }
  }

  return parsedCurrentSellingPrice;
};

export const getProductSellingPriceUnformatted = (
  inventory: IInventory,
  type?: "pieces" | "pack"
) => {
  const costPrice = getInventoryPrice(inventory);
  let pieces = 0;
  let pack = 0;

  if (typeof costPrice === "number") {
    // costPrice is a number
  } else if (costPrice !== null) {
    // costPrice is an object with "pieces" and "pack" properties
    pieces = costPrice.pieces ?? 0;
    pack = costPrice.pack ?? 0;
  }
  return inventory?.isVariation
    ? "--"
    : getInventoryType(inventory) === "PIECES_AND_PACK"
    ? `${Number(pieces)}(${Number(pack)})`
    : Number(getInventoryPrice(inventory));
};

const getTotalPriceByVariation = (variationId: string, inventory: IInventory): number => {
  const variationPrice = inventory?.Variations?.filter(
    (variation) => variation.variationId === variationId
  )[0].price;
  return variationPrice ?? 0;
};

const getVariationValue = (inventory: IInventory) => {
  let variationValue = 0;
  const variations = inventory?.Variations;

  if (variations) {
    for (let i = 0; i < variations?.length!; i++) {
      const price = getTotalPriceByVariation(variations[i]?.variationId!, inventory);

      const quantity = getVariantQuantity(variations[i]?.variationId!, inventory);
      variationValue += price * quantity;
    }
  }

  return variationValue;
};

export const productValue = (inventory: IInventory) => {
  const packPrice = inventory?.TrackableItem?.packPrice || 0;
  const unitPrice = inventory?.TrackableItem?.unitPrice || 0;
  const quantityInPacks = inventory?.quantityInPacks || 0;
  const quantityInPieces = inventory?.quantityInPieces || 0;

  if (inventory?.inventoryType === "NON_TRACKABLE") {
    return formatAmountIntl(undefined, Number(inventory?.NonTrackableItem?.sellingPrice));
  }

  if (inventory?.isVariation && inventory.isSharedQuantity) {
    const variations = inventory.Variations;

    const lowestVariationSellingPrice = Math.min(
      ...(variations?.map(({ price = 0 }) => Number(price)) || [0])
    );

    const variationValue = lowestVariationSellingPrice * (inventory.quantity as number) || 0;
    return formatAmountIntl(undefined, variationValue);
  }

  return !inventory?.isVariation
    ? formatAmountIntl(
        undefined,

        Number(formatNumber(packPrice * quantityInPacks + unitPrice * quantityInPieces))
      )
    : formatAmountIntl(undefined, Number(getVariationValue(inventory)));
};

export const handleNewPushNotificationMessage = async (
  { title, message, data = {} }: PushNotificationPayload,
  currentShopId: string,
  navigate: any,
  maximumRetry = 10
) => {
  if (data.shopId && data.shopId !== currentShopId) return;

  if (data.orderId) {
    const [order] = await rpcClient.request("getOrderById", { orderId: data.orderId || "" });

    if (!order) {
      if (maximumRetry < 1) return;
      return setTimeout(() => {
        handleNewPushNotificationMessage(
          { title, message, data },
          currentShopId,
          navigate,
          maximumRetry - 1
        );
      }, 5000);
    }
  }

  title = title || "New notification";
  const notification = new Notification(title, {
    body: message,
    silent: false,
  });

  if (data.orderId) {
    notification.onclick = (e) => {
      e.preventDefault();
      navigate("/kitchen-orders", {
        state: {
          orderId: data.orderId,
        },
      });
    };
  }
};

export const sanitizeNumber = (inputValue: string) => {
  const stringValue = String(inputValue) || "0";
  const sanitizedValue = stringValue?.replace(/[^\d.]/g, "");
  return sanitizedValue;
};

export const changeVariationCost = (
  setVariationValue: Function,
  variationName: string,
  cost: string
) => {
  setVariationValue((oldVarList: IVariationList) => {
    const copyOfOld: IVariationList = JSON.parse(JSON.stringify(oldVarList));
    if (copyOfOld[variationName]) {
      if (cost === "") {
        copyOfOld[variationName].cost = "";
      }
      if (Number(cost)) {
        copyOfOld[variationName].cost = cost;
      }
    } else {
      copyOfOld[variationName] = {
        price: "",
        quantity: "",
        cost: "",
      };
      if (cost === "") {
        copyOfOld[variationName].cost = "";
      }
      if (Number(cost)) {
        copyOfOld[variationName].cost = cost;
      }
    }
    return copyOfOld;
  });
};

export const changeVariationPrice = (
  setVariationValue: Function,
  variationName: string,
  price: string
) => {
  setVariationValue((oldVarList: IVariationList) => {
    const copyOfOld: IVariationList = JSON.parse(JSON.stringify(oldVarList));
    if (copyOfOld[variationName]) {
      if (price === "") {
        copyOfOld[variationName].price = "";
      }
      if (Number(price)) {
        copyOfOld[variationName].price = price;
      }
    } else {
      copyOfOld[variationName] = {
        price: "",
        quantity: "",
        cost: "",
      };
      if (price === "") {
        copyOfOld[variationName].price = "";
      }
      if (Number(price)) {
        copyOfOld[variationName].price = price;
      }
    }
    return copyOfOld;
  });
};

export const changeVariationQuantity = (
  setVariationValue: Function,
  variationName: string,
  quantity: string
) => {
  setVariationValue((oldVarList: IVariationList) => {
    const copyOfOld: IVariationList = JSON.parse(JSON.stringify(oldVarList));
    if (copyOfOld[variationName]) {
      if (quantity === "") {
        copyOfOld[variationName].quantity = "";
      }
      if (Number(quantity) || quantity === "0") {
        copyOfOld[variationName].quantity = quantity;
      }
    } else {
      copyOfOld[variationName] = {
        price: "",
        quantity: "",
        cost: "",
      };
      if (quantity === "") {
        copyOfOld[variationName].quantity = "";
      }
      if (Number(quantity) || quantity === "0") {
        copyOfOld[variationName].quantity = quantity;
      }
    }
    return copyOfOld;
  });
};

export const exportInventoryQuery = async (url: string, token: string) => {
  try {
    const response = await axios({
      method: "get",
      url: `${process.env.REACT_APP_LOCAL_SERVER_API}/${url}`,
      responseType: "blob",
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    const blob = new Blob([response.data], { type: response.data.type });
    return URL.createObjectURL(blob);
  } catch (error) {
    console.error("Failed to export inventories:", error);
  }
};

export const getSubtotal = (
  currentReceipt: IReceipt,
  totalCreditData?: { previouslyPaid: number; totalCredits: number }
): string => {
  const previousPaid = totalCreditData?.previouslyPaid || 0;
  const totalCredits = Math.abs(totalCreditData?.totalCredits || 0);
  const receiptTotalAmount = Number(currentReceipt?.totalAmount) || 0;

  const isCustomerTransaction = currentReceipt?.CustomerTransaction && currentReceipt?.customerId;
  const hasPreviousPayments = previousPaid + totalCredits > 0;

  const displayAmount =
    isCustomerTransaction && hasPreviousPayments
      ? previousPaid + totalCredits + receiptTotalAmount
      : receiptTotalAmount;

  const totalDiscount = currentReceipt?.totalDiscount || 0;
  const totalTaxAmount = currentReceipt?.totalTaxAmount || 0;

  const subTotal = displayAmount + totalDiscount - totalTaxAmount;

  return formatAmountIntl(undefined, subTotal);
};

export const getTaxOnProduct = (
  price: number,
  totalTaxAmount: number,
  totalAmountWithoutTax: number,
  isTaxInclusive: boolean
) => {
  const taxRate = (totalTaxAmount || 0) / (totalAmountWithoutTax + (totalTaxAmount || 0));

  // Calculate the tax portion of the given price
  const taxAmount = isTaxInclusive ? price * taxRate : 0;

  return parseFloat(taxAmount.toFixed(2));
};

export const salesUnitamount = ({
  val,
  print = false,
}: {
  val: ISales;

  print?: boolean;
}) => {
  const { amount, discount, taxAmount } = val;

  const salesAmount = Number(amount) + Number(discount) - Number(taxAmount);

  return print && !isPositive(discount) ? salesAmount - Number(discount) : salesAmount;
};

export const calculateTotalSalesAmount = (
  sales: ISales[],
  taxDetails: {
    totalAmountWithoutTax: number;
    totalTaxAmount: number;
    isTaxInclusive: boolean;
  },
  print = false
): number => {
  return sales.reduce((total, val) => {
    const itemAmount = salesUnitamount({
      val,
      print,
    });

    return total + Number(itemAmount);
  }, 0);
};
