/* eslint-disable indent */
/* eslint-disable func-call-spacing */
import { useLocation } from "react-router-dom";
import { sidebarDetails } from "../Exclusives/sidebarItems";
import { MenuButton, MenuDropdown, MenuNotificationPill } from "./newSideStyles";
import { Flex, Span } from "../../GlobalStyles/CustomizableGlobal.style";
import { hasAnyPermission } from "../../helper/comparisons";
import { sidebarDetailsType } from "../../interfaces/companyConfig.interface";
import { UserPermissions } from "../../app/slices/roles";
import { PlusOrMinusIcon } from "./SIdebarIcons";
import { FC, Fragment, useEffect, useRef, useState } from "react";
import { fontSizes } from "../../GlobalStyles/theme";

const NewSidebar: FC<{ handleClick: Function; userPermissions: UserPermissions }> = ({
  handleClick,
  userPermissions,
}) => {
  const { pathname } = useLocation();
  const firstRenderRef = useRef(true);
  const [currentState, setCurrentState] = useState(0);

  useEffect(() => {
    if (firstRenderRef.current) {
      // This is the first render
      firstRenderRef.current = false;
    }
  }, []);

  return (
    <>
      {sidebarDetails.map(
        ({ name, icon, subRoutes, path, allowedRoles, activeIcon }: sidebarDetailsType, index) => {
          const showNotification = false;
          const shouldAccess = hasAnyPermission(allowedRoles, userPermissions);
          const isActive = pathname === `${path}`;
          const isSubRoute: boolean = !!subRoutes?.some(
            (item) => item.path === pathname
          ) as boolean;

          if (isSubRoute && firstRenderRef.current) {
            setCurrentState(index);
            firstRenderRef.current = false;
          }

          return (
            <Fragment key={index}>
              {subRoutes && subRoutes.length > 0 ? (
                <MenuDropdown active={currentState === index}>
                  <Flex
                    onClick={() =>
                      currentState !== index ? setCurrentState(index) : setCurrentState(9000)
                    }
                    justifyContent="space-between"
                    alignItems="center"
                    height="2.5rem"
                    width="100%"
                    hoverBg="#291f57"
                    hover
                    bg={currentState === index ? "#2E2261" : ""}
                    padding="0.5rem 1rem"
                    borderRadius="0.5rem"
                  >
                    <Flex width="80%" alignItems="center" fontSize={fontSizes.small} gap="0.4rem">
                      <img id="icon" src={currentState === index ? activeIcon : icon} alt="" />
                      <Span fontSize={fontSizes.base} className="header-menu">
                        {name}
                      </Span>
                    </Flex>
                    <Flex width="20%" alignItems="center" gap="0.4rem" justifyContent="flex-end">
                      {showNotification && <MenuNotificationPill>5</MenuNotificationPill>}
                      <PlusOrMinusIcon open={currentState === index} />
                    </Flex>
                  </Flex>
                  {subRoutes && subRoutes.length > 0 && (
                    <div className="dropdown">
                      {subRoutes.map((item) => {
                        const comp: string | undefined = item?.path;
                        const currentRoute = pathname === `${comp}`;
                        const shldAccess = hasAnyPermission(item?.allowedRoles, userPermissions);
                        return (
                          <MenuButton
                            isSubroute
                            key={item.path}
                            active={currentRoute}
                            height="2rem"
                            onClick={() => {
                              handleClick(item.path as string, shldAccess);
                              setCurrentState(index);
                            }}
                          >
                            <Flex width="100%" alignItems="center" gap="0.4rem">
                              <Span fontSize={fontSizes.base}>{item.name}</Span>
                            </Flex>
                          </MenuButton>
                        );
                      })}
                    </div>
                  )}
                </MenuDropdown>
              ) : (
                <MenuButton
                  active={isActive}
                  onClick={() => {
                    handleClick(path as string, shouldAccess);
                    setCurrentState(9000);
                  }}
                >
                  <Flex width="70%" alignItems="center" gap="0.4rem">
                    <img id="icon" src={isActive ? activeIcon : icon} alt="" />
                    <Span fontSize={fontSizes.base} className="header-menu">
                      {name}
                    </Span>
                  </Flex>
                  <Flex width="25%" alignItems="center" justifyContent="flex-end">
                    {showNotification && <MenuNotificationPill>5</MenuNotificationPill>}
                  </Flex>
                </MenuButton>
              )}
            </Fragment>
          );
        }
      )}
    </>
  );
};

export default NewSidebar;
