import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

function getWindowDimensions () {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
}

export default function useWindowDimensions () {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize () {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export const useResizeObserver = (containerRef: React.MutableRefObject<HTMLDivElement | null>) => {
  const [size, setSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (!containerRef.current) return;

    const handleResize = () => {
      setSize({
        width: containerRef?.current?.offsetWidth || 0,
        height: containerRef?.current?.offsetHeight || 0,
      });
    };

    const resizeObserver = new ResizeObserver(() => handleResize());

    resizeObserver.observe(containerRef.current);
    handleResize();

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return { size };
};

export const useScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname]);
};
