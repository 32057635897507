import styled, { css } from "styled-components";
import { Colors } from "../../GlobalStyles/theme";
interface IButtonProps {
  buttonColor?: string;
  valueColor?: string;
  size?: string;
  borderColor?: string;
  borderSize?: string;
  borderRadius?: string;
  fontSize?: string;
  width?: string;
  height?: string;
  margin?: string;
  border?: boolean;
  disabled?: boolean;
  display?: string;
  noWrap?: boolean;
  smResponsive?: string;
  mdResponsive?: string;
  padding?: string;
  gap?: string;
}

export const Button = styled.button`
  appearance: none;
  outline: none;
  align-items: center;
  justify-content: center;
  display: ${(props: IButtonProps) => props.display ?? "unset"};
  font-size: ${(props: IButtonProps) => props.fontSize || ".75rem"};
  background-color: ${(props: IButtonProps) => props.buttonColor || "gray"};
  border-width: ${(props: IButtonProps) => props.borderSize || "1px"};
  border-color: ${(props: IButtonProps) => props.borderColor || "transparent"};
  color: ${(props: IButtonProps) => props.valueColor || "rgb(112, 76, 182)"};
  padding-block: ${(props: IButtonProps) =>
    props.size === "md" ? 1 * 0.5 : props.size === "lg" ? 1.5 * 0.5 : 0.75 * 0.5}rem;
  padding-inline: ${(props: IButtonProps) =>
    props.size === "md" ? 1 * 1 : props.size === "lg" ? 1.5 * 1 : 0.75 * 1}rem;
  border-radius: ${(props: IButtonProps) => props.borderRadius || "0px"};
  width: ${(props: IButtonProps) => props.width || "100%"};
  cursor: ${({ disabled }: IButtonProps) => (disabled ? "not-allowed" : "pointer")};
  transition: all 0.15s;
  border: ${(props: IButtonProps) => (props.border ? `1px solid ${props.borderColor}` : "unset")};
  height: ${(props: IButtonProps) => props.height || "3.125rem"};
  padding: ${(props: IButtonProps) => props.padding || "0 0.5rem"};

  /* Hover Effects */
  :hover {
    opacity: ${({ disabled }: IButtonProps) => (disabled ? "0.5" : "0.8")};
    transform: ${({ disabled }: IButtonProps) => (disabled ? "none" : " scale(1.02)")};
    box-shadow: ${({ disabled }: IButtonProps) =>
      disabled ? "none" : "0 4px 8px rgba(0, 0, 0, 0.2)"};
  }

  /* Active State */
  :active {
    transform: scale(0.98);
    box-shadow: none;
  }

  :disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
  margin: ${(props: IButtonProps) => props.margin || "0"};
  opacity: ${({ disabled }: IButtonProps) => (disabled ? "0.5" : "1")};
  gap: ${({ gap }: IButtonProps) => gap ?? "unset"};
  align-items: center;

  ${({ noWrap }: IButtonProps) =>
    noWrap &&
    `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}

  @media (max-width: 770px) {
    ${({ mdResponsive }) => css`
      ${mdResponsive}
    `}
  }

  @media (max-width: 430px) {
    ${({ smResponsive }) => css`
      ${smResponsive}
    `}
  }
`;
