import React, { useCallback, useEffect, useState } from "react";
import { Container } from "./syncBanner.styles";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { clientServerSocket } from "../../services/clientServerSocket";
import { Flex, Span } from "../../GlobalStyles/CustomizableGlobal.style";
import { Colors, fontSizes } from "../../GlobalStyles/theme";
import { ModalWrapper } from "../modal/ModalWrapper";
import { CancelButtonComp } from "../button/CancelButton";
import { Button } from "../button/Button";
import ShareIcon from "../../assets/shareWhite.svg";
import { useQuery } from "@apollo/client";
import { UsersAttr } from "../../interfaces/user.interface";
import { GET_ALL_USER } from "../../schema/auth.schema";
import { toggleSnackbarOpen } from "../../app/slices/snacbar";
import { getCurrentShop } from "../../app/slices/shops";
import { getUserPermissions } from "../../app/slices/roles";
import { getCurrentUser, IIntial } from "../../app/slices/userInfo";
import _ from "lodash";
import { defaultCashierRoles, defaultSalesRoles, DefualtManagerRoles } from "../../utils/defaultRoles.utils";
import { differenceInDays } from "date-fns";
import { rpcClient } from "../../helper/rpcClient";
import moment from "moment";
import { isDesktop } from "../../utils/helper.utils";
import BulbOrange from "../../assets/bulbOrange.svg";
import BulbRed from "../../assets/bulbRed.svg";
import useNetworkStatus from "../../utils/checkInternet.utils";

interface ISyncBanner {
  setShowSyncModal: (val: boolean) => void;
}

export const SyncBanner: React.FC<ISyncBanner> = ({ setShowSyncModal }) => {
  const { bannerData } = useAppSelector((state) => state.syncStatus);
  const [displayMessage, setDisplayMessage] = useState("");

  const { isOnline } = useNetworkStatus();
  const unsyncedRecordsCount = bannerData?.totalRecordsToPush || 0;
  const error = bannerData?.error;

  const messages = {
    hangingRecords: `You have hanging records: ${unsyncedRecordsCount} unsynced records.`,
    noInternet: "No Internet Connection",
    noInternetWithRecords: `No Internet Connection: ${unsyncedRecordsCount} unsynced records.`,
    error: `No Internet Connection: ${error}`
  };

  useEffect(() => {
    if (!isOnline) {
      if (unsyncedRecordsCount > 0) {
        setDisplayMessage(messages.noInternetWithRecords);
      } else if (error) {
        setDisplayMessage(messages.error);
      } else {
        setDisplayMessage(messages.noInternet);
      }
    } else if (unsyncedRecordsCount > 0) {
      setDisplayMessage(messages.hangingRecords);
    } else {
      setDisplayMessage("");
    }
  }, [isOnline, error, unsyncedRecordsCount]);

  return (
    <Container>
      <Flex height="100%" alignItems="center" flexFlow="column" onClick={() => setShowSyncModal(true)}>
        <Span style={{ display: "flex", alignItems: "center", justifyContent: "center" }} height="100%" textAlign="center" width="100%" color={Colors.white} noWrap>{displayMessage}</Span>
      </Flex>
    </Container>
  );
};

export const SyncModal: React.FC<ISyncBanner> = ({ setShowSyncModal }) => {
  const { bannerData } = useAppSelector((state) => state.syncStatus);
  const dispatch = useAppDispatch();
  const currentShop = useAppSelector(getCurrentShop);
  const userRole = useAppSelector(getUserPermissions);
  const currentUser = useAppSelector(getCurrentUser);
  const subscriptions = useAppSelector((state) => state.subscriptions);
  const { isOnline } = useNetworkStatus();

  const { running, error } = bannerData || {};

  const [currentUserRole, setCurrentUserRole] = useState<string>();
  const [shopOwnerInfo, setShopOwnerInfo] = useState<IIntial>();
  const [syncStatus, setSyncStatus] = useState("");
  const [appVersion, setAppVersion] = useState("");
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const currentUserSubscriptions = subscriptions.subscriptions || [];
  const expiryDate =
    currentUserSubscriptions[0]?.expiryDate !== null
      ? currentUserSubscriptions[0]?.expiryDate
      : currentUserSubscriptions[0]?.gracePeriodExpiryDate;
  const daysLeft = Math.ceil(differenceInDays(new Date(expiryDate), new Date())) - 1;
  const formattedDaysLeft =
    currentUserSubscriptions[0]?.packageNumber === 0 ? "No expiry date" : `${daysLeft} day(s) left`;

  const { data: allUserData } = useQuery<{
    getAllUsers: UsersAttr[];
  }>(GET_ALL_USER, {
    variables: {
      shopId: currentShop?.shopId,
    },
    skip: !currentShop?.shopId,
    onError (err) {
      dispatch(toggleSnackbarOpen(err?.message || err?.graphQLErrors[0]?.message));
    },
  });

  const users = allUserData?.getAllUsers || [];
  const staffCount = users.length - 1;

  useEffect(() => {
    const isManager = _.isEqual(userRole.permissions, DefualtManagerRoles.rolePermissions);
    const isCashier = _.isEqual(userRole.permissions, defaultCashierRoles.rolePermissions);
    const isSales = _.isEqual(userRole.permissions, defaultSalesRoles.rolePermissions);
    isManager
      ? setCurrentUserRole("Manager")
      : isSales
        ? setCurrentUserRole("Sales Person")
        : isCashier
          ? setCurrentUserRole("Cashier")
          : setCurrentUserRole("Custom Role");
  }, []);

  const userId = currentShop?.userId;

  useEffect(() => {
    setIsError(Boolean(bannerData?.error) || !isOnline);
  }, [bannerData?.error, isOnline]);

  const unsyncedRecordsCount = bannerData?.totalRecordsToPush;
  const percentage = bannerData?.percentage || 0;
  const hasRecords = (bannerData?.totalRecordsToPush || 0) > 0;

  const messages = {
    hangingRecordsShort: "Hanging Records",
    noInternetWithRecords: `No Internet Connection: ${unsyncedRecordsCount} unsynced records.`,
    error: `${!isOnline ? "No Internet Connection " : ""} ${error || ""}`,
    pullError: error ? "Error Pulling Records" : "No Internet Connection",
    pushError: !isOnline ? "Hanging Records" : "No Internet Connection",
    running: `Syncing(${percentage}%)`,
  };

  useEffect(() => {
    if (!bannerData) return;

    if (!isOnline) {
      setSyncStatus(hasRecords ? messages.pushError : messages.pullError);
      return;
    }

    if (isError) {
      setSyncStatus(messages.pushError);
    } else if (running) {
      setSyncStatus(messages.running);
    } else {
      setSyncStatus("Online");
    }
  }, [bannerData, isOnline, running, isError, hasRecords]);

  useEffect(() => {
    if (!bannerData) return;

    if (!isOnline) {
      setErrorMessage(hasRecords ? messages.noInternetWithRecords : messages.error);
    } else if (isError) {
      setErrorMessage(messages.error);
    }
  }, [isOnline, bannerData, hasRecords, isError]);

  useEffect(() => {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).manager?.receive("app-version", (_event, value) => {
      setAppVersion(value.version);
    });
  }, []);

  const fetchUserInfo = useCallback(() => {
    rpcClient.request("getUserInfo", { userId })
      .then((userInfo) => {
        setShopOwnerInfo(userInfo);
      });
  }, [userId]);

  const getAppVersion = useCallback(() => {
    (window as any).manager?.send("app-version");
  }, []);

  useEffect(() => {
    fetchUserInfo();
  }, [fetchUserInfo]);

  useEffect(() => {
    getAppVersion();
  }, []);

  return (
    <ModalWrapper onClose={() => setShowSyncModal(false)}>
      <Flex
        bg="white"
        borderRadius="0.5rem"
        padding="1rem"
        maxHeight="90dvh"
        height="100%"
        flexFlow="column"
        width="40rem"
        mdResponsive="width: 95dvw"
        overflow="auto"
      >
        <Flex alignItems="center" justifyContent="space-between" gap="1.5rem">
          <Span color={Colors.primaryColor} fontWeight="600" fontSize={fontSizes.large}>
            Synchronization
          </Span>
          <CancelButtonComp handleClick={() => setShowSyncModal(false)} />
        </Flex>
        <Span>Keep your data up-to-date across all devices in real-time.</Span>

        <Flex
          bg={isError ? Colors.lightRed : Colors.lightSecondaryColor}
          borderRadius="0.5rem"
          padding="1rem"
          flexFlow="column"
          gap="0.5rem"
          width="100%"
          margin="1rem 0 0 0"
          alignItems="center"
        >
          <img style={{ width: "2rem" }} src={isError ? BulbRed : BulbOrange} alt="" />
          {isError ? (
            <Span color={Colors.danger} textAlign="center">{errorMessage}</Span>
          ) : (
            <Span color={Colors.primaryColor} textAlign="center">
              Make sure you’re connected to the internet to get latest changes on your business records.
            </Span>
          )}
        </Flex>
        <Flex margin="1.5rem 0 0 0" gap="1rem" flexFlow="column">
          <Flex alignItems="center" justifyContent="space-between" style={{ borderBottom: "1px solid #ccc" }} padding="0 0 0.5rem 0">
            <Span color={Colors.grey4}>Sync Status</Span>
            <Span fontWeight="600" color={running ? Colors.secondaryColor : isError ? Colors.danger : syncStatus === "Online" ? Colors.onlineGreen : Colors.primaryColor} fontStyle="italic">
              {syncStatus}
            </Span>
          </Flex>

          <Flex alignItems="center" justifyContent="space-between" style={{ borderBottom: "1px solid #ccc" }} padding="0 0 0.5rem 0">
            <Span color={Colors.grey4}>Last Sync Date</Span>
            <Span fontWeight="600" color={Colors.primaryColor} fontStyle="italic">
              {moment(bannerData?.lastSyncDate).format("Do MMM @ hh: mm a") || "---"}
            </Span>
          </Flex>

          <Flex alignItems="center" justifyContent="space-between" style={{ borderBottom: "1px solid #ccc" }} padding="0 0 0.5rem 0">
            <Span color={Colors.grey4}>No of Invited Staff</Span>
            <Span fontWeight="600" color={Colors.primaryColor} fontStyle="italic">
              {staffCount} Staff
            </Span>
          </Flex>

          {
            isDesktop() && (
              <Flex alignItems="center" justifyContent="space-between" style={{ borderBottom: "1px solid #ccc" }} padding="0 0 0.5rem 0">
                <Span color={Colors.grey4}>Application Version</Span>
                <Span fontWeight="600" color={Colors.primaryColor} fontStyle="italic">
                  {appVersion}
                </Span>
              </Flex>
            )
          }
        </Flex>
        <Flex width="100%" padding="1rem" gap="1rem" borderRadius="0.5rem" margin="1.5rem 0 0 0" bg={Colors.lightBg}
          mdResponsive="flex-direction: column">
          <Flex flexFlow="column" width="55%" height="100%" padding="0.5rem" gap="1rem"
            mdResponsive="width: 100%">
            <Flex flexFlow="column">
              <Span color={Colors.grey4}>Shop Name</Span>
              <Span>{currentShop?.shopName}</Span>
            </Flex>
            <Flex flexFlow="column">
              <Span color={Colors.grey4}>Shop Email</Span>
              <Span noWrap>{shopOwnerInfo?.email || "---"}</Span>
            </Flex>
            <Flex flexFlow="column">
              <Span color={Colors.grey4}>Subscription Plan</Span>
              <Flex alignItems="center" gap="0.5rem">
                <Span>{currentUserSubscriptions[0]?.packageName}</Span> |
                <Span color={Colors.secondaryColor} fontWeight="600">{formattedDaysLeft}</Span>
              </Flex>
            </Flex>
            </Flex>
          <Flex flexFlow="column" width="45%" bg="white" height="100%" borderRadius="0.5rem" padding="0.5rem"
            gap="1rem"
            mdResponsive="width: 100%">
            <Span fontWeight="600" fontSize={fontSizes.large}>My Information</Span>
            <Flex flexFlow="column">
              <Span color={Colors.grey4}>Email Address</Span>
              <Span noWrap>{currentUser.email}</Span>
            </Flex>
            <Flex flexFlow="column">
              <Span color={Colors.grey4}>Role</Span>
              <Span>
                {userRole?.isShopOwner ? "Shop Owner" : currentUserRole}
              </Span>
            </Flex>
            </Flex>
        </Flex>

          {/* <Button
            onClick={() => initiateShareDb()}
            backgroundColor={Colors.primaryColor}
            size="md"
            fontSize={`${fontSizes.base}`}
            borderRadius="0.75rem"
          width="100%"
          style={{ minHeight: "3rem" }}
          height="3rem"
          margin="1rem 0 0 0"
            color="#fff"
            borderColor="transparent"
            borderSize="0px"
          >
            <Flex alignItems="center" gap="0.5rem" justifyContent="center" width="100%">
              <img src={ShareIcon} alt="" />
              <Span fontSize={fontSizes.base}> Share DB</Span>
            </Flex>
          </Button> */}
        </Flex>
    </ModalWrapper>
  );
};
